import React from 'react';
// import { Icon, Label } from 'semantic-ui-react';
// import { openModal } from '../../../../../app/common/modals/modalReducer';
// import { useDispatch } from 'react-redux';
import {
	foreignerVisaType,
	nationality,
	noJobPayInDetailJobType,
} from '../../../../../app/api/dropdownOption';

function CrwfDayWorkerBasicInfo({
	currentUserProfile,
	MyTextInput,
	MyDateInput,
	isForiegner,
	MySelectInput,
	dayWorker,
	timeWorker,
	bizIncomeWorker,
}) {
	// const dispatch = useDispatch();

	const dayWorkerType = dayWorker
		? '일용직'
		: timeWorker
		? '시급직'
		: bizIncomeWorker
		? '사업소득직'
		: '';

	// function workerRegStep1_4YTOpen() {
	// 	dispatch(openModal({ modalType: 'WorkerRegStep5' }));
	// }

	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				{currentUserProfile?.authLevel >= 90 && (
					<>
						<div
							style={{
								width: '100%',
								marginBottom: '30px',
								marginTop: '30px',
								fontSize: '20px',
							}}>
							✓ 회사 기초정보
						</div>

						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label='회사명'
								placeholder='노무법인 인업 세종본부'
								name='companyName'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label='대표자 성명'
								placeholder='세종대왕'
								name='company.ceoName'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label='회사 주소'
								placeholder='세종특별시 갈매로 351 4115호'
								name='company.addr'
								autoComplete='off'
							/>
						</div>
					</>
				)}
			</div>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div>
					<span
						style={{
							width: '100%',
							marginBottom: '10px',
							marginTop: '30px',
							fontSize: '20px',
						}}>
						✓ {dayWorkerType} 근로조건 입력
					</span>
					{/* <Label style={{color:'blue'}} onClick={() => whatIsPayDacYTOpen()}><Icon size='big' name='youtube' /></Label> */}
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'flex-end',
						width: '100%',
						marginBottom: '30px',
						marginTop: '30px',
						fontSize: '17px',
					}}>
					<span style={{ color: 'red' }}>* </span>
					<span style={{ color: 'blue' }}>인적 정보 설정</span>
					{/* <span style={{ fontSize: '8px' }}>
						(설명은 오른쪽 동영상을 참조하세요.)
					</span> */}
					{/* <span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '10px',
							cursor: 'pointer',
						}}>
						<Label
							style={{ color: 'red' }}
							onClick={() => workerRegStep1_4YTOpen()}>
							<Icon size='big' name='youtube' />
							인적 정보 설정
						</Label>
					</span> */}
				</div>
				<div
					className='margin'
					style={{ width: '33%', marginRight: '3px', marginBottom: '50px' }}>
					<MyTextInput
						label={
							<>
								<span style={{ color: 'blue' }}>* 직원이름</span>
								<span style={{ color: 'red' }}>
									(변경시 신규 직원으로 복사됨)
								</span>
							</>
						}
						name='worker.name'
						placeholder='홍길동'
						autoComplete='off'
					/>
				</div>
				<div
					className='margin'
					style={{ width: '33%', marginRight: '3px', marginBottom: '50px' }}>
					<MyDateInput
						label={
							<>
								<span style={{ color: 'blue' }}>* 입사일</span>
							</>
						}
						name='workerEnteredDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div
					className='margin'
					style={{ width: '33%', marginRight: '3px', marginBottom: '50px' }}>
					<MyDateInput
						label='* 계약만료일(있는 경우)'
						name='workerEndDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<br />
				<br />
				<div className='margin' style={{ width: '32.5%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<>
								<span style={{ color: 'blue' }}>* 주민등록증 앞번호</span>
							</>
						}
						name='workerSocialNumberFront'
						placeholder={'주민등록번호 앞 6자리'}
						autoComplete='off'
					/>
				</div>
				-
				<div className='margin' style={{ width: '32.5%', marginRight: '3px' }}>
					<MyTextInput
						// type='password'
						label={
							<>
								<span style={{ color: 'blue' }}>* 주민등록증 뒷번호</span>
							</>
						}
						name='workerSocialNumberBack'
						placeholder={'주민등록번호 뒤 7자리'}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='* 사번(있는 경우 입력하세요.)'
						name='worker.comNumber'
						placeholder='회사에서 부여한 사번이 있는 경우 그 사번을 쓰세요.'
						autoComplete='off'
					/>
				</div>
				{isForiegner ? (
					<>
						<div
							className='margin'
							style={{ width: '49%', marginRight: '3px' }}>
							<MySelectInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 국적</span>
									</>
								}
								name='foreigner.nationality'
								autoComplete='off'
								options={nationality}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '49%', marginRight: '3px' }}>
							<MySelectInput
								label={
									<>
										<span style={{ color: 'blue' }}>* 비자 종류</span>
									</>
								}
								placeholder='비자종류'
								name='foreigner.visaType'
								options={foreignerVisaType}
							/>
						</div>
					</>
				) : null}
				<div className='margin' style={{ width: '33%' }}>
					<MyTextInput
						label={
							<>
								<span style={{ color: 'blue' }}>* 부서명</span>
							</>
						}
						name='workerDivisions'
						placeholder='부서'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label={<span style={{ color: 'blue' }}>*직무</span>}
						name='noJobPayDetail'
						// placeholder='회계 사무원, 판매 종사원, 경비원 등'
						autoComplete='off'
						options={noJobPayInDetailJobType}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<>
								<span style={{ color: 'blue' }}>* 구체적 담당업무</span>
							</>
						}
						name='workDetailField'
						placeholder='최대한 자세히 기술'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='* 직원 E-mail'
						name='worker.email'
						placeholder='직원 이메일(교부의무 면제기능 사용시 필수)'
						autoComplete='off'
					/>
				</div>
			</div>
		</div>
	);
}

export default CrwfDayWorkerBasicInfo;
