import React from 'react';
import { josaGaType, josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLDCHoliUnder15({
	selectedClientReq4In,
	companyName,
	worker,
	isExceptTimeLawWorker,
}) {
	// 1주 소정근로시간
	const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;
	const companyUn = josaUnType(companyName);
	const workerGa = josaGaType(worker);

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제6조 휴일 및 휴가</strong>
				</span>
				{isExceptTimeLawWorker ? (
					<ul>
						<li>{`① ${companyUn} 근로기준법 제63조에 따라 ${worker}에게 근로기준법 제55조의 휴일(주휴일 포함)조항을 적용하지 아니한다.`}</li>
						<li>{`② ${companyUn} ${workerGa} 초단시간 근로자이므로 근로기준법 제18조 제2항에 따라 근로기준법 제60조의 연차유급휴가를 부여하지 아니한다.`}</li>
					</ul>
				) : (
					<ul>
						<li>
							① {worker}의 1주 소정근로시간은 {weekLBTime}시간으로 1주
							15시간미만 초단시간 근로자이므로 {companyUn} 근로기준법 제18조
							제2항에 따라 {worker}에게 근로기준법 제55조의 휴일(주휴일 포함)을
							부여하지 아니한다.
						</li>
						<li>
							② ${companyUn} ${workerGa} 초단시간 근로자이므로 근로기준법 제18조
							제2항에 따라 근로기준법 제60조의 연차유급휴가를 부여하지 아니한다.
						</li>
					</ul>
				)}
			</div>
		</>
	);
}
