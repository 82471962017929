import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Grid, Input, Loader } from 'semantic-ui-react';
import {
	delay,
	freeComClient,
	isAgentF,
	numberToCommaString,
} from '../../../app/common/util/util';
import { clickConfirmedTimeOfLaborContract } from '../../../app/firestore/firestoreService4In';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../companyActions';
import PaydocuFilters from './PaydocuFilters';
import PaydocuList from './PaydocuList';
import PaydocuShortCut from './PaydocuShortCut';
import PaydocuWorkerFilters from './PaydocuWorkerFilters';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocuDashboard() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { paydocus, filter, yearMonth, clientInfo, superAdminForFreeTrue } =
		useSelector((state) => state.companyReducer);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { loading } = useSelector((state) => state.async);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const { authenticated } = useSelector((state) => state.auth);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	const isWorker = currentUserProfile?.isWorker;
	const isDayWorker =
		currentUserProfile?.isDayWorker || currentUserProfile?.isTimeWorker
			? true
			: false;
	const isBizWorkerUser = currentUserProfile?.isBizWorker ? true : false;
	const paydocuWorkerUid = currentUserProfile?.paydocuWorkerUid;

	const workerViewData = paydocus.filter((paydocu) => {
		const workerId = paydocu?.workerInfo?.workerId;

		// workerId가 문자열인지 또는 배열인지 확인
		if (typeof workerId === 'string' || Array.isArray(workerId)) {
			return workerId?.includes(paydocuWorkerUid);
		}

		// workerId가 문자열이나 배열이 아닌 경우 필터에서 제외
		return false;
	});

	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel,
		currentUserProfile?.isAgent
	);

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	useEffect(() => {
		dispatch(
			fetchPaydocusFormFirestoreService4In(
				filter,
				yearMonth,
				paydocuWorkerUid,
				clientInfo
			)
		).then(() => {});
		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, filter, yearMonth, paydocuWorkerUid, clientInfo]);

	// 필터링된 paydocus를 다시 가져오는 함수
	const refetchPaydocus = (filter, yearMonth, paydocuWorkerUid, clientInfo) => {
		dispatch(
			fetchPaydocusFormFirestoreService4In(
				filter,
				yearMonth,
				paydocuWorkerUid,
				clientInfo
			)
		);
	};

	const numOfWorkers = +currentUserProfile?.numOfWorkers;

	function viewLaborContractHandlerWorker(paydocuWorkerUid) {
		delay(1000);
		history.push(`/clientReqs4In/${paydocuWorkerUid}`);
		clickConfirmedTimeOfLaborContract(paydocuWorkerUid);
	}

	function viewLaborContractHandlerDayWorker(paydocuWorkerUid) {
		delay(1000);
		history.push(`/clientReqDaylyPayContract/${paydocuWorkerUid}`);
		clickConfirmedTimeOfLaborContract(paydocuWorkerUid);
	}

	function goToAnnualLeavesWorker() {
		history.push('/annualLeavesWorker');
	}

	const regularWorkerData = (searchResults) => {
		return searchResults.filter(
			(row) =>
				row.isRegularWorker ||
				row.isRegularWorekr ||
				row.isExceptTimeLawWorker ||
				row.isEtcWorker ||
				(row.isRegularWorker === '' &&
					!row.dayWorker &&
					!row.isTimeWorker &&
					!row.isBizWorker) ||
				(row.isRegularWorekr === '' &&
					!row.dayWorker &&
					!row.isTimeWorker &&
					!row.isBizWorker)
		);
	};

	const dayWorerData = (searchResults) => {
		return searchResults.filter((row) => row.dayWorker || row.isTimeWorker);
	};

	const bizWorerData = (searchResults) => {
		return searchResults.filter((row) => row.isBizWorker);
	};

	const inputString = yearMonth;
	const year = inputString.slice(0, 4);
	const month = inputString.slice(4, 6);
	const formattedString = isBizWorkerUser
		? '사업소득 명세서'
		: isWorker
		? '임금 명세서'
		: `${year}년 ${month}월 급여작업`;

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;

	const admin = currentUserProfile?.authLevel >= 100 ? true : false;

	const freeDeleteDisable =
		!superAdminForFreeTrue && freeComClient(authLevel, ceoName) ? true : false;

	const companyWageWorkComment = clientInfo?.companyWageWorkComment;

	const serviceFee =
		clientInfo?.price &&
		numberToCommaString(clientInfo?.price) + '원 (부가세 별도)';

	return (
		<>
			{isWorker ? (
				<>
					{isBizWorkerUser ? null : (
						<>
							{isDayWorker ? (
								<Button
									style={{ color: 'blue', background: 'yellow' }}
									onClick={() =>
										viewLaborContractHandlerDayWorker(paydocuWorkerUid)
									}>
									근로계약서
								</Button>
							) : (
								<Button
									style={{ color: 'blue', background: 'yellow' }}
									onClick={() =>
										viewLaborContractHandlerWorker(paydocuWorkerUid)
									}>
									근로계약서
								</Button>
							)}
						</>
					)}
					{numOfWorkers >= 5 && !isDayWorker && !isBizWorkerUser && (
						<Button onClick={() => goToAnnualLeavesWorker()} color='teal'>
							연차사용내역
						</Button>
					)}
					<br />
					<br />
					<hr />
				</>
			) : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			<>
				{isWorker ? (
					<PaydocuWorkerFilters
						loading={loading}
						isWorker={isWorker}
						filter={filter}
						isBizWorkerUser={isBizWorkerUser}
					/>
				) : (
					<PaydocuFilters
						isAgent={isAgent}
						loading={loading}
						isWorker={isWorker}
					/>
				)}
				{isWorker ? null : (
					<>
						직원성명 :{' '}
						<Input
							type='text'
							placeholder='Search'
							value={searchTerm}
							onChange={handleChange}
						/>
						<br />
						<br />
						<p style={{ color: 'red' }}>
							* 마감 버튼을 클릭하면 직원이 급여 열람가능합니다.
						</p>
						<p>
							* 모든 직원 마감 이후에 새로 고침하여 마감여부를 다시한번
							확인하세요.
						</p>
						<p style={{ color: 'red' }}>
							* 마감 이후 직원이 명세서를 열람한 경우 삭제가 되지 않습니다.
						</p>
					</>
				)}
			</>
			<Grid>
				<Grid.Column width={16}>
					<>
						<h1 style={{ textAlign: 'center' }}>{formattedString}</h1>
						<br />
						{authenticated && (
							<PaydocuList
								refetchPaydocus={refetchPaydocus}
								filteredWorker={regularWorkerData}
								paydocus={isWorker ? workerViewData : paydocus}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								searchTerm={searchTerm}
								filter={filter}
								yearMonth={yearMonth}
								paydocuWorkerUid={paydocuWorkerUid}
								isWorker={isWorker}
								isBizWorkerUser={isBizWorkerUser}
								freeDeleteDisable={freeDeleteDisable}
							/>
						)}
						{authenticated && !isWorker && (
							<PaydocuList
								filteredWorker={dayWorerData}
								isAgent={isAgent}
								filter={filter}
								yearMonth={yearMonth}
								paydocus={isWorker ? workerViewData : paydocus}
								paydocuWorkerUid={paydocuWorkerUid}
								loading={loading}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								searchTerm={searchTerm}
								freeDeleteDisable={freeDeleteDisable}
								refetchPaydocus={refetchPaydocus}
							/>
						)}
						{authenticated && !isWorker && (
							<PaydocuList
								filteredWorker={bizWorerData}
								isAgent={isAgent}
								filter={filter}
								yearMonth={yearMonth}
								paydocus={isWorker ? workerViewData : paydocus}
								paydocuWorkerUid={paydocuWorkerUid}
								loading={loading}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								searchTerm={searchTerm}
								freeDeleteDisable={freeDeleteDisable}
								refetchPaydocus={refetchPaydocus}
							/>
						)}
						<hr />
						<div
							style={{
								whiteSpace: 'pre-line',
								lineHeight: '1.5',
								fontSize: '20px',
								color: 'blue',
							}}>
							{admin && serviceFee}
						</div>
						<hr />
						<hr />
						<div
							style={{
								whiteSpace: 'pre-line',
								lineHeight: '1.5',
								fontSize: '20px',
								color: 'blue',
							}}>
							{admin && companyWageWorkComment}
						</div>
						<hr />
						<br />
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
