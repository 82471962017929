// import { Field, FieldArray } from 'formik';
import React from 'react';
import { Tab } from 'semantic-ui-react';
import MyDateInput from '../../../../../app/common/form/MyDateInput';
import MyTextInput from '../../../../../app/common/form/MyTextInput';
import MyTextArea from '../../../../../app/common/form/MyTextArea';
import CrwfWorkerBasicInfoChildLeaves from './CrwfWorkerBasicInfoChildLeaves';
import {
	healthIn4DiscountType,
	healthIn4Type,
	pensionIn4PublicJobType,
	pensionIn4Type,
	workerSupportFundYesOrNot,
	workField,
} from '../../../../../app/api/dropdownOption';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import WorkerFormStep1 from './WorkerFormStep1';
import WorkerFormCompanyInfo from './WorkerFormCompanyInfo';
import WorkerFormStep11 from './WorkerFormStep11';
import WorkerFormStep1ContractTpye from './WorkerFormStep1ContractType';

export default function CrwfWorkerBasicInfo({
	isCoWorkSemusa,

	values,
	currentUserProfile,

	authLevel,
	setEnterDate,
	prevWorker,
	over52Permission,
	selectedClientReq4In,
	setEditingLeave,
	setFormValues,
}) {
	const MenuItemContent = () => (
		<>
			<span style={{ color: 'blue' }}>(1-1) 인사기초</span>
			<span style={{ color: 'red' }}>[필수]</span>
		</>
	);

	const MenuItemContent2 = () => (
		<>
			<span style={{ color: 'blue' }}>(1-2) 4대보험 취득</span>
			<span style={{ color: 'red' }}>[필수]</span>
		</>
	);

	const panes = [];

	if (authLevel >= 100) {
		panes.push({
			menuItem: '회사정보',
			key: '회사정보',
			render: () => (
				<Tab.Pane>
					<WorkerFormCompanyInfo
						currentUserProfile={currentUserProfile}
						MyTextInput={MyTextInput}
						MySelectInput={MySelectInput}
						over52Permission={over52Permission}
					/>
				</Tab.Pane>
			),
		});
	}

	panes.push(
		{
			menuItem: {
				content: <MenuItemContent />,
				key: '인사기초정보[필수]',
			},
			render: () => (
				<Tab.Pane>
					<WorkerFormStep1
						isCoWorkSemusa={isCoWorkSemusa}
						values={values}
						MyTextInput={MyTextInput}
						MyDateInput={MyDateInput}
						workField={workField}
						MyTextArea={MyTextArea}
						authLevel={authLevel}
						setEnterDate={setEnterDate}
						prevWorker={prevWorker}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: {
				content: <MenuItemContent2 />,
				key: '4대보험 취득정보[필수]',
			},
			render: () => (
				<Tab.Pane>
					<WorkerFormStep11
						MyTextInput={MyTextInput}
						MySelectInput={MySelectInput}
						pensionIn4Type={pensionIn4Type}
						healthIn4Type={healthIn4Type}
						workerSupportFundYesOrNot={workerSupportFundYesOrNot}
						MyTextArea={MyTextArea}
						pensionIn4PublicJobType={pensionIn4PublicJobType}
						healthIn4DiscountType={healthIn4DiscountType}
						values={values}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: '(1-3) 계약직/수습직 설정',
			key: '계약직/수습직 설정',
			render: () => (
				<Tab.Pane>
					<WorkerFormStep1ContractTpye
						MyDateInput={MyDateInput}
						values={values}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: '(1-4) 출산전후 휴가등록',
			key: '출산전후 휴가등록',
			render: () => (
				<Tab.Pane>
					<CrwfWorkerBasicInfoChildLeaves //step 1-3
						values={values}
						MyDateInput={MyDateInput}
						selectedClientReq4In={selectedClientReq4In}
						setEditingLeave={setEditingLeave}
						setFormValues={setFormValues}
					/>
				</Tab.Pane>
			),
		}
	);

	return (
		<>
			<Tab panes={panes} />
		</>
	);
}
