import moment from 'moment';
import React from 'react';
import {
	josaGaType,
	josaUlType,
	josaUnType,
} from '../../../../../app/common/util/util';

export default function ClientReqDLDCHoliUp15Worker5Up({
	contractOldWorker,
	selectedClientReq4In,
	companyName,
	worker,
	isEtcWorker,
	isExceptTimeLawWorker,
	currentUserProfile,
	clientInfo,
}) {
	const isRetiredWorker = selectedClientReq4In?.retiredDate ? true : false;
	const weekLBHoliday = selectedClientReq4In?.etc?.paidOffdays
		? selectedClientReq4In?.etc?.paidOffdays
		: selectedClientReq4In?.paidOffdays;
	const annualLeaveFinDate = isRetiredWorker
		? selectedClientReq4In?.finBaseDate
		: currentUserProfile?.finBaseDate;
	const alDate = moment(new Date(annualLeaveFinDate));
	const finBaseDateMonth = moment(
		moment().set({ month: alDate.get('month') })
	).format('MM');
	const finBaseDateDate = moment(
		moment().set({ date: alDate.get('date') })
	).format('DD');

	const accountAL = selectedClientReq4In?.annualLeaves;

	const companyUn = josaUnType(companyName);
	const companyGa = josaGaType(companyName);

	const workerUl = josaUlType(worker);

	const companyId = clientInfo?.id ? clientInfo?.id : currentUserProfile?.id;

	const gongHoliText = `${companyUn} 근로기준법 제55조 제2항에 따라 ${worker}에게 관공서의 공휴일에 관한 규정(제2조 제1호(일요일)은 제외)에 따른 공휴일과 대체공휴일을 유급 휴일로 부여하되, 근로자대표와의 서면합의가 있는 경우 휴일을 특정한 근로일로 대체할 수 있다.`;
	const mayDateText = `${companyUn} ${worker}에게 5월1일 근로자의 날을 근로자의날에 관한 법률에 따라 유급 휴일로 부여한다.`;

	const weekHoliText = isEtcWorker
		? `${companyUn} 근로기준법 제55조 제1항에 따라 ${worker}에게 유급(1주 소정근로일을 개근하지 아니한 경우는 무급) 주휴일을 
      ${weekLBHoliday}에 부여한다.`
		: `${companyUn} 근로기준법 제55조 제1항에 따라 ${worker}에게 유급(1주 소정근로일을 개근하지 아니한 경우는 무급) 주휴일을 
      매주 ${weekLBHoliday}요일에 부여한다.`;

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제6조 휴일 및 휴가</strong>
				</span>
				<ul>
					<li>① {mayDateText}</li>
					{isExceptTimeLawWorker ? (
						<li>
							② {companyUn} 근로기준법 제63조에 따라 {worker}에게 근로기준법
							제55조의 휴일(주휴일 포함)조항을 적용하지 아니한다.
						</li>
					) : (
						<li>② {weekHoliText}</li>
					)}

					{accountAL === '회계년도기준' ||
					accountAL === '특정일(회계년도 등)기준' ? (
						<>
							{companyId === 'uoOYN49eVWe2waff2dszFt8qazk2' ||
							companyId === 'g5rXAA8A9cOiKfYKiEtNfCFzbH12' ? (
								<li>
									③ {companyUn} {worker}에게 근로기준법 제60조에 따른
									연차유급휴가를 부여하되 효율적인 연차관리를 위해 회계년도(매년{' '}
									{finBaseDateMonth}월 {finBaseDateDate}일, 매월{' '}
									{finBaseDateDate}
									일) 기준으로 년 단위 연차와 월 단위 연차를 부여하며, 동법
									제61조에 따라 적법하게 연차유급휴가를 사용촉진한 경우
									연차유급휴가미사용 수당 지급의무를 면하며, 근로자대표와의
									서면합의에 의거 연차유급휴가일을 갈음하여 특정근로일에{' '}
									{workerUl} 휴무시킬 수 있다.
								</li>
							) : (
								<li>
									③ {companyUn} {worker}에게 근로기준법 제60조에 따른
									연차유급휴가를 부여하되 효율적인 연차관리를 위해 회계년도(매년{' '}
									{finBaseDateMonth}월 {finBaseDateDate}일, 매월{' '}
									{finBaseDateDate}
									일) 기준으로 년 단위 연차와 월 단위 연차를 부여하며, 동법
									제61조에 따라 적법하게 연차유급휴가를 사용촉진한 경우
									연차유급휴가미사용 수당 지급의무를 면하며, 근로자대표와의
									서면합의에 의거 연차유급휴가일을 갈음하여 특정근로일에{' '}
									{workerUl} 휴무시킬 수 있다.
								</li>
							)}

							{<li>④ {gongHoliText}</li>}
						</>
					) : (
						<>
							<li>
								③ {companyUn} {worker}에게 근로기준법 제60조에 따른 {worker}의
								입사일 단위로 계산되는 연차유급휴가를 부여하고,
								{companyGa} {worker}에게 동법 제61조에 따라 적법하게
								연차유급휴가 사용촉진을 한 경우 연차유급휴가미사용 수당
								지급의무를 면하며,
								{companyUn} 근로자대표와의 서면합의에 의거 {worker}의
								연차유급휴가일을 갈음하여 특정 근로일에 {workerUl} 휴무시킬 수
								있다.
							</li>
							<li>④ {gongHoliText}</li>
						</>
					)}
				</ul>
			</div>
		</>
	);
}
