import React from 'react';
import { josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLDCHoliUp15Worker5Under({
	selectedClientReq4In,
	companyName,
	worker,
	isEtcWorker,
	isExceptTimeLawWorker,
}) {
	const weekLBHoliday = selectedClientReq4In?.etc?.paidOffdays
		? selectedClientReq4In?.etc?.paidOffdays
		: selectedClientReq4In?.paidOffdays;
	const companyUn = josaUnType(companyName);

	const mayDateText = `${companyUn} ${worker}에게 5월1일 근로자의 날을 근로자의날에 관한 법률에 따라 유급 휴일로 부여한다.`;
	const weekHoliText = isEtcWorker
		? `${companyUn} 근로기준법 제55조 제1항에 따라 ${worker}에게 유급(1주 소정근로일을 개근하지 아니한 경우는 무급) 주휴일을 
      ${weekLBHoliday}에 부여한다.`
		: `${companyUn} 근로기준법 제55조 제1항에 따라 ${worker}에게 유급(1주 소정근로일을 개근하지 아니한 경우는 무급) 주휴일을 
      매주 ${weekLBHoliday}요일에 부여한다.`;

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제6조 휴일 및 휴가</strong>
				</span>
				{isExceptTimeLawWorker ? (
					<ul>
						<li>{`① ${mayDateText}`}</li>
						<li>{`② ${companyUn} 근로기준법 제63조에 따라 ${worker}에게 근로기준법 제55조의 휴일(주휴일 포함)조항을 적용하지 아니한다.`}</li>
						<li>
							{`③ ${companyUn} 5인 미만 사업장으로 ${worker}에게 근로기준법 제11조 제2항 제63조, 동법 시행령 제7조에 의거 휴일 및 연차유급휴가 관련 근로기준법 제55조 제2항(국공휴일), 제56조(연장/야간/휴일 가산임금), 제57조 ~ 제59조, 제60조(연차유급휴가)~제62조), 제70조(야간근로와 휴일근로의 제한-여성 및 연소자)을 적용하지 아니한다.`}
						</li>
					</ul>
				) : (
					<ul>
						<li>{`① ${mayDateText}`}</li>
						<li>{`② ${weekHoliText}`}</li>
						<li>
							{`③ ${companyUn} 5인 미만 사업장으로 ${worker}에게 근로기준법 제11조 제2항, 동법 시행령 제7조에 의거 휴일 및 연차유급휴가 관련 근로기준법 제55조 제2항(국공휴일), 제56조(연장/야간/휴일 가산임금), 제57조 ~ 제59조, 제60조(연차유급휴가)~제62조), 제70조(야간근로와 휴일근로의 제한-여성 및 연소자)을 적용하지 아니한다.`}
						</li>
					</ul>
				)}
			</div>
		</>
	);
}
