import React from 'react';
import {
	traineeEndDateValue,
	workerEnteredDateValue,
} from '../../../../../app/common/util/util';

export default function WorkerFormStep1ContractType({ values, MyDateInput }) {
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='contractEndDate'
						label='(1) 계약직 근로계약 종료일'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='againContractStartDate'
						label={
							<span style={{ fontWeight: 'bold', color: '#007bff' }}>
								(2) 계약직 2차이상 재 계약일(갱신계약 시작일)
							</span>
						}
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div
					style={{
						width: '100%',
						marginBottom: '10px',
						marginTop: '50px',
						fontSize: '13px',
					}}>
					● <span style={{ color: 'teal' }}>수습직</span>인 경우
				</div>
				<div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
					<MyDateInput
						label='(2-1) 수습시작일'
						name='traineeStartDate'
						placeholderText={workerEnteredDateValue(values)}
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
					<MyDateInput
						label='(2-2) 수습종료일'
						name='traineeEndDate'
						placeholderText={traineeEndDateValue(values)}
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
			</div>
		</>
	);
}
