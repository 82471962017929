import React from 'react';
import {
	josaGaType,
	josaUlType,
	josaUnType,
} from '../../../../../app/common/util/util';

export default function ClientReqDLCEndContract({
	numOfWorkers,
	companyName,
	worker,
}) {
	const companyUn = josaUnType(companyName);
	const companyGa = josaGaType(companyName);

	const workerGa = josaGaType(worker);
	const workerUn = josaUnType(worker);
	const workerUl = josaUlType(worker);

	const resignPeriodText = `① ${workerUn} 민법 제660조(고용의 해지통고) 제1항에 의거 ${companyName}에게 언제든지 근로계약의 해지 통고를 할 수 있으나, 
  ${companyGa} 사직서를 수리하지 않은 이상, ${workerGa} 동법 제3항에 의거 근로계약의 해지를 통고 한 해당월이 아닌 다음달 말일이 경과하면 ${workerGa} 통고한 근로계약 해지의 효력이 생긴다.`;

	return (
		<>
			{numOfWorkers >= 5 ? (
				<div className='jau__contents'>
					<span style={{ fontSize: '1rem' }}>
						<strong>제8조 근로관계의 종료(해고, 해지, 해고예고)</strong>
					</span>
					<ul>
						<li>{`${resignPeriodText}`}</li>
						<li>
							{`② ${companyUn} 근로계약 기간의 유무와 관계 없이 ${worker}에게 근로기준법 제23조(해고 등의 제한), 제24조(경영상 이유에 의한 해고의 제한) 
              규정에 따른 정당한 해고 사유가 있는 경우에는 ${workerUl} 해고할 수 있다. 단, ${workerUl} 해고할 때에는 동법 제27조(해고 사유 등의 서면통지)에 따라 
              해고통보서에 해고 사유와 시기를 서면으로 명시하여 ${worker}에게 통보해야 한다.`}
						</li>
						<li>
							{`③ 제2항에 따른 ${worker}의 해고사유는 ${companyName}의 취업규칙 및 관련 내규 등이 있으면 그에 따라야 한다.`}
						</li>
					</ul>
				</div>
			) : (
				<div className='jau__contents'>
					<span style={{ fontSize: '1rem' }}>
						<strong>제8조 근로관계의 종료(해고조항 제외 및 해고예고)</strong>
					</span>
					<ul>
						<li>{`${resignPeriodText}`}</li>
						<li>{`② ${companyUn} 5인미만 사업장으로 근로기준법 제23조(해고 등의 제한) 동법 제24조(경영상 이유에 의한 해고 의 제한) 
              동법 제27조(해고 사유 등의 서면통지) 조항이 적용되지 않는 사업장으로 해당 조항의 준수 의무는 없다.`}</li>
					</ul>
				</div>
			)}
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제9조 기타</strong>
				</span>
				<ul>
					<li>
						① 이 계약에 정함이 없는 사항은 노동법령 또는 기타 법령, 사회 통념에
						따른다.
					</li>
				</ul>
			</div>
		</>
	);
}
