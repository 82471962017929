import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../companyActions';
import PaydocuFilters from './PaydocuFilters';
import PaydocusDayWorkerTableList from './PaydocusDayWorkerTableList';
import PaydocuShortCut from './PaydocuShortCut';
import { isAgentF } from '../../../app/common/util/util';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocusDayWorkerTableDashboard() {
	const dispatch = useDispatch();
	const { paydocus, filter, yearMonth, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { loading } = useSelector((state) => state.async);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const { authenticated } = useSelector((state) => state.auth);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const isWorker = currentUserProfile?.isWorker;

	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel,
		currentUserProfile?.isAgent
	);
	const paydocuWorkerUids = currentUserProfile?.paydocuWorkerUid;

	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;
	useEffect(() => {
		const fetchClientReqs = async () => {
			try {
				await dispatch(fetchClientReqs4In(filter));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchClientReqs();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	useEffect(() => {
		const fetchPaydocus = async () => {
			try {
				await dispatch(
					fetchPaydocusFormFirestoreService4In(
						filter,
						yearMonth,
						paydocuWorkerUids,
						clientInfo
					)
				);
			} catch (error) {
				console.error('Error fetching paydocus:', error);
			}
		};

		fetchPaydocus();

		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, filter, yearMonth, paydocuWorkerUids, clientInfo]);

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<h1 style={{ textAlign: 'center' }}>일당직 임금대장</h1>
			<PaydocuFilters
				loading={loading}
				isWorker={isWorker}
				isAgent={isAgent}
				clientInfo={clientInfo}
			/>
			<>
				{isWorker ? null : (
					<>
						직원성명 검색 :{' '}
						<Input
							type='text'
							placeholder='Search'
							value={searchTerm}
							onChange={handleChange}
						/>
						<br />
					</>
				)}
			</>
			<Grid>
				<Grid.Column width={16}>
					<>
						<hr />
						{authenticated && (
							<PaydocusDayWorkerTableList
								paydocus={paydocus}
								loading={loading}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								searchTerm={searchTerm}
								companyName={companyName}
							/>
						)}
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
