import React from 'react';
import { josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLCDWage({ worker, companyName }) {
	const companyUn = josaUnType(companyName);

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제7조 임금 및 퇴직금</strong>
				</span>
				<ul>
					<li>
						① {companyUn} 근로기준법 제17조에 따른 {worker}의 임금의 구성항목,
						계산방법, 지급방법 및 퇴직급여보장법상의 퇴직금 등 임금 및 퇴직금과
						관련하여 본 근로계약서의 내용이 반영된 별도의 임금계약서를 {worker}
						에게 동 법률에 의거 교부한다.
					</li>
				</ul>
			</div>
		</>
	);
}
