import React from 'react';
import { Tab } from 'semantic-ui-react';
import CrwfWorkerRetireInfo41 from './CrwfWorkerRetireInfo41';
// import CrwfWorkerRetireInfo42 from './CrwfWorkerRetireInfo42';
// import CrwfWorkerRetireInfo43 from './CrwfWorkerRetireInfo43';

export default function CrwfWorkerRetireInfo({
	numOfWorkers,
	selectedClientReq4In,
	eiOutDetailType,
	setEiOutDetailType,
	noticeFireOrNot,
	setNoticeFireOrNot,
	firedPaperOrNot,
	setFiredPaperOrNot,
	paydocus,
	workerEnteredDate,
	workerRetiredDate,
	setRetiredDate,
}) {
	const panes = [];

	panes.push(
		{
			menuItem: {
				content: '(4-1) 퇴직일/4대보험 상실',
				key: '퇴직일/4대보험 상실',
			},
			render: () => (
				<>
					<CrwfWorkerRetireInfo41
						numOfWorkers={numOfWorkers}
						selectedClientReq4In={selectedClientReq4In}
						eiOutDetailType={eiOutDetailType}
						setEiOutDetailType={setEiOutDetailType}
						noticeFireOrNot={noticeFireOrNot}
						setNoticeFireOrNot={setNoticeFireOrNot}
						firedPaperOrNot={firedPaperOrNot}
						setFiredPaperOrNot={setFiredPaperOrNot}
						paydocus={paydocus}
						workerEnteredDate={workerEnteredDate}
						workerRetiredDate={workerRetiredDate}
						setRetiredDate={setRetiredDate}
					/>
				</>
			),
		}
		// {
		// 	menuItem: {
		// 		content: '(4-2) 국민/건강 상실',
		// 		key: '국민/건강 상실',
		// 	},
		// 	render: () => (
		// 		<>
		// 			<CrwfWorkerRetireInfo42
		// 				noJobPay={noJobPay}
		// 				setNoJobPay={setNoJobPay}
		// 			/>
		// 		</>
		// 	),
		// }
		// {
		//   menuItem: {
		//     content: '(4-3) 금품청산',
		//     key: '금품청산',
		//   },
		//   render: () => (
		//     <>
		//       <CrwfWorkerRetireInfo43
		//         numOfWorkers={numOfWorkers}
		//         selectedClientReq4In={selectedClientReq4In}
		//         retiredDate={retiredDate}
		//         paydocus={paydocus}
		//         workerEnteredDate={workerEnteredDate}
		//         workerRetiredDate={workerRetiredDate}
		//       />
		//     </>
		//   ),
		// },
	);

	return (
		<>
			<Tab panes={panes} />
		</>
	);
}
