import React from 'react';
import {
	// childLeaveOfAbsenceEndDateValue,
	// childLeaveOfAbsenceStartDateValue,
	// childShortTimeEndDateValue,
	maternityLeaveEndDateValue,
} from '../../../../../app/common/util/util';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import {
	// childLeaveOfAbsenceTypeOption,
	// childShortTimeTypeOption,
	// leaveOfAbsenceTypeOption,
	maternityLeaveTypeOption,
} from '../../../../../app/api/dropdownOption';
// import moment from 'moment';
// import { Button, Table } from 'semantic-ui-react';
// import {
// 	deleteChildLeaveOfAbsenceFromWorkerFirestore,
// 	deleteChildShortTimeFromWorkerFirestore,
// 	deleteLeaveOfAbsenceFromWorkerFirestore,
// 	deleteMaternityLeaveFromWorkerFirestore,
// } from '../../../../../app/firestore/firestoreService4In';
// import { openModal } from '../../../../../app/common/modals/modalReducer';
// import { useDispatch } from 'react-redux';
// import { updateChildLeaveOfAbsenceInWorkerFirestore, updateChildShortTimeInWorkerFirestore, updateLeaveOfAbsenceInWorkerFirestore, updateMaternityLeaveInWorkerFirestore } from '../../../../../app/firestore/firestoreService4In';

export default function CrwfWorkerBasicInfoChildLeaves({
	values,
	MyDateInput,
	selectedClientReq4In,
	setEditingLeave,
	setFormValues,
}) {
	// const dispatch = useDispatch();

	// const thisYear = new Date().getFullYear();

	// const childLeaveOfAbsenceStartDateM = moment(
	// 	values?.childLeaveOfAbsenceStartDate
	// );
	// const childLeaveOfAbsenceEndDateM = moment(
	// 	values?.childLeaveOfAbsenceEndDate
	// ).add(1, 'days');
	// const childSupportFund =
	// 	childLeaveOfAbsenceEndDateM.diff(childLeaveOfAbsenceStartDateM, 'months') >=
	// 	1
	// 		? true
	// 		: false;

	// const handleEditClick = (leaveType, leave) => {
	// 	if (!leave) return;

	// 	// leaveType에 따른 key 결정
	// 	const startDateKey =
	// 		leaveType === 'maternityLeave'
	// 			? 'maternityLeaveStartDate'
	// 			: leaveType === 'childLeaveOfAbsence'
	// 			? 'childLeaveOfAbsenceStartDate'
	// 			: leaveType === 'childShortTime'
	// 			? 'childShortTimeStartDate'
	// 			: 'leaveOfAbsenceStartDate';
	// 	const endDateKey =
	// 		leaveType === 'maternityLeave'
	// 			? 'maternityLeaveEndDate'
	// 			: leaveType === 'childLeaveOfAbsence'
	// 			? 'childLeaveOfAbsenceEndDate'
	// 			: leaveType === 'childShortTime'
	// 			? 'childShortTimeEndDate'
	// 			: 'leaveOfAbsenceEndDate';
	// 	const typeKey =
	// 		leaveType === 'maternityLeave'
	// 			? 'maternityLeaveType'
	// 			: leaveType === 'childLeaveOfAbsence'
	// 			? 'childLeaveOfAbsenceType'
	// 			: leaveType === 'childShortTime'
	// 			? 'childShortTimeType'
	// 			: 'leaveOfAbsenceType';

	// 	// 상태 변경 전 조건 추가
	// 	setEditingLeave((prev) => {
	// 		if (
	// 			prev?.type === leaveType &&
	// 			prev?.data?.[startDateKey] === leave[startDateKey]
	// 		) {
	// 			return prev; // 상태 중복 갱신 방지
	// 		}
	// 		return { type: leaveType, data: leave };
	// 	});

	// 	setFormValues((prev) => ({
	// 		...prev,
	// 		leaveType: leave[typeKey] || '',
	// 		startDate: moment(leave[startDateKey].seconds * 1000).format(
	// 			'YYYY-MM-DD'
	// 		),
	// 		endDate: moment(leave[endDateKey].seconds * 1000).format('YYYY-MM-DD'),
	// 		type: leaveType,
	// 	}));

	// 	alert('입력창의 내용을 수정 후 저장버튼을 클릭하세요.');
	// };

	// const handleDeleteClick = (leaveType, leaveId) => {
	// 	const workerId = selectedClientReq4In?.id; // 근로자 ID를 가져오는 방법에 따라 수정하세요

	// 	switch (leaveType) {
	// 		case 'maternityLeave':
	// 			deleteMaternityLeaveFromWorkerFirestore(workerId, leaveId)
	// 				.then(() => {
	// 					alert('출산휴가 Data 삭제 성공!');
	// 				})
	// 				.catch((error) => {
	// 					console.error('출산휴가 삭제 중 오류 발생: ', error);
	// 				});
	// 			break;
	// 		case 'childLeaveOfAbsence':
	// 			deleteChildLeaveOfAbsenceFromWorkerFirestore(workerId, leaveId)
	// 				.then(() => {
	// 					alert('육아휴직 Data 삭제 성공!');
	// 				})
	// 				.catch((error) => {
	// 					console.error('육아휴직 삭제 중 오류 발생: ', error);
	// 				});
	// 			break;
	// 		case 'childShortTime':
	// 			deleteChildShortTimeFromWorkerFirestore(workerId, leaveId)
	// 				.then(() => {
	// 					alert('육아기 근로시간 단축 Data 삭제 성공!');
	// 				})
	// 				.catch((error) => {
	// 					console.error('육아기 근로시간 단축 삭제 중 오류 발생: ', error);
	// 				});
	// 			break;
	// 		case 'leaveOfAbsence':
	// 			deleteLeaveOfAbsenceFromWorkerFirestore(workerId, leaveId)
	// 				.then(() => {
	// 					alert('개인 휴직 Data 삭제 성공!');
	// 				})
	// 				.catch((error) => {
	// 					console.error('개인 휴직 삭제 중 오류 발생: ', error);
	// 				});
	// 			break;
	// 		default:
	// 			console.error('알 수 없는 휴가 유형: ', leaveType);
	// 	}
	// };

	// 데이터 가공 함수
	// const processLeaveData = (
	// 	leaveData,
	// 	maternityData,
	// 	childShortTimeData,
	// 	leaveOfAbsenceData
	// ) => {
	// 	const formatDate = (timestamp) => {
	// 		if (!timestamp || typeof timestamp.seconds !== 'number') {
	// 			console.error('Invalid timestamp:', timestamp);
	// 			return 'Invalid Date'; // 기본값 반환
	// 		}
	// 		const date = new Date(timestamp.seconds * 1000);
	// 		return date.toLocaleDateString(); // 날짜 형식 변환
	// 	};

	// 	const renderLeaveRows = (data, type) => {
	// 		return data.map((leave, index) => (
	// 			<Table.Row key={`${type}-${index}`}>
	// 				<Table.Cell>{index + 1}</Table.Cell>
	// 				<Table.Cell>
	// 					{leave.maternityLeaveType ||
	// 						leave.childLeaveOfAbsenceType ||
	// 						leave.childShortTimeType ||
	// 						leave.leaveOfAbsenceType}
	// 				</Table.Cell>
	// 				<Table.Cell>
	// 					{formatDate(
	// 						leave.maternityLeaveStartDate ||
	// 							leave.childLeaveOfAbsenceStartDate ||
	// 							leave.childShortTimeStartDate ||
	// 							leave.leaveOfAbsenceStartDate
	// 					)}
	// 				</Table.Cell>
	// 				<Table.Cell>
	// 					{formatDate(
	// 						leave.maternityLeaveEndDate ||
	// 							leave.childLeaveOfAbsenceEndDate ||
	// 							leave.childShortTimeEndDate ||
	// 							leave.leaveOfAbsenceEndDate
	// 					)}
	// 				</Table.Cell>
	// 				<Table.Cell>
	// 					<Button
	// 						type='button'
	// 						color='blue'
	// 						onClick={() => handleEditClick(type, leave)}>
	// 						데이터 선택
	// 					</Button>
	// 					<Button
	// 						type='button'
	// 						color='red'
	// 						onClick={() => handleDeleteClick(type, leave.id)}>
	// 						삭제
	// 					</Button>
	// 				</Table.Cell>
	// 			</Table.Row>
	// 		));
	// 	};

	// 	return (
	// 		<Table celled textAlign='center'>
	// 			<Table.Header>
	// 				<Table.Row>
	// 					<Table.HeaderCell>No</Table.HeaderCell>
	// 					<Table.HeaderCell>유형</Table.HeaderCell>
	// 					<Table.HeaderCell>시작일</Table.HeaderCell>
	// 					<Table.HeaderCell>종료일</Table.HeaderCell>
	// 					<Table.HeaderCell>실행</Table.HeaderCell>
	// 				</Table.Row>
	// 			</Table.Header>
	// 			<Table.Body>
	// 				{maternityData && renderLeaveRows(maternityData, 'maternityLeave')}
	// 				{leaveData && renderLeaveRows(leaveData, 'childLeaveOfAbsence')}
	// 				{childShortTimeData &&
	// 					renderLeaveRows(childShortTimeData, 'childShortTime')}
	// 				{leaveOfAbsenceData &&
	// 					renderLeaveRows(leaveOfAbsenceData, 'leaveOfAbsence')}
	// 			</Table.Body>
	// 		</Table>
	// 	);
	// };

	// const processedData = processLeaveData(
	// 	selectedClientReq4In?.childLeaveOfAbsences,
	// 	selectedClientReq4In?.maternityLeaves,
	// 	selectedClientReq4In?.childShortTimes,
	// 	selectedClientReq4In?.leaveOfAbsences
	// );

	// function maternityLeaveYTOpen() {
	// 	dispatch(openModal({ modalType: 'MaternityLeave' }));
	// }

	// function childLeaveYTOpen() {
	// 	dispatch(openModal({ modalType: 'ChildLeave' }));
	// }

	// function childShortTimeYTOpen() {
	// 	dispatch(openModal({ modalType: 'ChildShortTime' }));
	// }

	// function etcLeaveYTOpen() {
	// 	dispatch(openModal({ modalType: 'EtcLeave' }));
	// }

	return (
		<div style={{ marginBottom: '-10px', marginTop: '-10px' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '17px',
				}}>
				{/* <span style={{ color: 'red' }}>* </span>
				<span style={{ color: 'blue' }}>휴직 등록</span> */}
				{/* <span style={{ fontSize: '8px' }}>
					(설명은 오른쪽 동영상을 참조하세요.)
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '10px',
						cursor: 'pointer',
					}}>
					<Label
						style={{ color: 'red' }}
						onClick={() => maternityLeaveYTOpen()}>
						<Icon size='big' name='youtube' />
						출산전후휴가 등록
					</Label>
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '170px',
						cursor: 'pointer',
					}}>
					<Label style={{ color: 'red' }} onClick={() => childLeaveYTOpen()}>
						<Icon size='big' name='youtube' />
						육아휴직 등록
					</Label>
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '310px',
						cursor: 'pointer',
					}}>
					<Label
						style={{ color: 'red' }}
						onClick={() => childShortTimeYTOpen()}>
						<Icon size='big' name='youtube' />
						육아기근로시간 단축 등록
					</Label>
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '510px',
						cursor: 'pointer',
					}}>
					<Label style={{ color: 'red' }} onClick={() => etcLeaveYTOpen()}>
						<Icon size='big' name='youtube' />
						기타 휴직 등록
					</Label>
				</span> */}
			</div>

			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'flex-end',
						width: '100%',
						marginBottom: '30px',
						marginTop: '30px',
						fontSize: '13px',
					}}>
					<span style={{ color: 'teal' }}>출산전후휴가</span>
					{/* 					
					또는{' '}
					<span style={{ color: 'teal' }}>
						육아휴직, 육아기 근로시간 단축 등 휴직이 있는
					</span>
					인 경우{' '}
					<span style={{ color: 'red' }}> (* 고용안정지원금 판단 포함)</span> */}
				</div>
				<>
					{/* <div className='margin' style={{ width: '99%', marginTop: '-15px' }}>
						<span style={{ color: 'red' }}>
							(* 해당 사항이 있는 경우 시작일, 종료일, 종류(또는 분할횟수)를
							모두 입력해야 저장됩니다.)
						</span>
					</div> */}
					<div className='margin' style={{ width: '22%', marginRight: '3px' }}>
						<MySelectInput
							label='(12)-1 출산전후휴가 종류'
							name='maternityLeaveType'
							placeholder='일반출산휴가, 유사산휴가 선택'
							options={maternityLeaveTypeOption}
						/>
					</div>
					<div className='margin' style={{ width: '22%', marginRight: '3px' }}>
						<MyDateInput
							name='maternityLeaveStartDate'
							label='(12)-2 출산전후휴가 시작일'
							placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '55%', marginRight: '3px' }}>
						<MyDateInput
							name='maternityLeaveEndDate'
							label={
								<>
									(12)-3 출산전후휴가 종료일 -
									<span style={{ color: 'teal' }}>
										{' '}
										(법정 종료일 까지만 AI NOMUSA 임금명세서에서 출산전후휴가
										공제액이 자동계산됨)
									</span>
								</>
							}
							placeholderText={`법정 휴가 마지막 일자 : ${maternityLeaveEndDateValue(
								values
							)}`}
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>
					{values?.maternityLeaveType === '배우자 출산 휴가 1차' ||
					values?.maternityLeaveType === '배우자 출산 휴가 2차' ? (
						<div
							className='margin'
							style={{ width: '99%', marginTop: '-15px' }}>
							<span style={{ color: 'blue' }}>
								(* 유급 10일(휴일 및 휴무일 제외) 배우자 출산휴가는 배우자가
								출산한 날로부터 90일까지 사용가능하고 1회에 한해 나누어 사용할
								수 있습니다.)
							</span>
						</div>
					) : null}
					{/* <div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MySelectInput
							label='(13)-1 육아휴직 분할'
							name='childLeaveOfAbsenceType'
							placeholder='예, 출산후 육아휴직 1년 전체사용'
							options={childLeaveOfAbsenceTypeOption}
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyDateInput
							name='childLeaveOfAbsenceStartDate'
							label='(13)-2 육아휴직 시작일'
							placeholderText={childLeaveOfAbsenceStartDateValue(values)}
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyDateInput
							name='childLeaveOfAbsenceEndDate'
							label='(13)-3 육아휴직 종료일'
							placeholderText={childLeaveOfAbsenceEndDateValue(values)}
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>

					{values?.childLeaveOfAbsenceStartDate ? (
						<div className='margin' style={{ width: '99%', marginTop: '-1px' }}>
							<span style={{ color: 'blue' }}>
								(
								{`* ${thisYear}년 현재 육아휴직은 임신중에도 사용할 수 있고, 출산전 사용한 휴직을 제외한 기간을 해당 자녀 출산 후 만 8세(초등학교 2학년)까지 총 2회 분할 사용이 가능합니다.`}
								)
							</span>
						</div>
					) : null}
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MySelectInput
							label='(13)-4 육아기 근로시간 단축 분할'
							name='childShortTimeType'
							placeholder='육아기 근로시간 단축 1차'
							options={childShortTimeTypeOption}
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyDateInput
							name='childShortTimeStartDate'
							label='(13)-5 육아기 근로시간 단축 시작일'
							placeholderText={childLeaveOfAbsenceStartDateValue(values)}
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyDateInput
							name='childShortTimeEndDate'
							label='(13)-6 육아기 근로시간 단축 종료일'
							placeholderText={childShortTimeEndDateValue(values)}
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>

					{values?.childShortTimeStartDate ? (
						<div className='margin' style={{ width: '99%', marginTop: '-1px' }}>
							<span style={{ color: 'blue' }}>
								(* 2019년10월1일 이후 현재 육아기 근로시간 단축은 최소 3개월
								단위로 분할 사용 가능하고, 육아휴직을 사용하지 않는 경우 그
								기간까지 합산하여 최장 2년간 사용가능합니다.)
							</span>
						</div>
					) : null}
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MySelectInput
							label='(14)-1 기타 휴직 등 종류'
							name='leaveOfAbsenceType'
							placeholder='회사귀책, 근로자 귀책, 정직 등'
							options={leaveOfAbsenceTypeOption}
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyDateInput
							name='leaveOfAbsenceStartDate'
							label='(14)-2 기타 휴직 등 시작일'
							placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyDateInput
							name='leaveOfAbsenceEndDate'
							label='(14)-3 기타 휴직 등 종료일'
							placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
							timeFormat='HH:mm'
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
						/>
					</div>
					{childSupportFund ? (
						<p>
							*{' '}
							<span style={{ color: 'red' }}>
								위 직원은 육아휴직 등 지원금 대상자
							</span>
							<span style={{ color: 'blue' }}>
								로 최초3개월간은 200만원씩, 나머지 기간동은 월 30만원씩 지급될
								수 있습니다.
							</span>
							실제 지원금 수령은 요건이 까다롭습니다. 고용안정센터 또는
							자문노무사에게 만드시 검토를 받고 신청하시기 권장드립니다.
						</p>
					) : null}

					<p style={{ color: 'blue', fontSize: '20px', marginTop: '100px' }}>
						* 상기 등록된 휴가 및 휴직 내용은 메인 화면 직원 List의
						"근로자명부"에 모두 표시됩니다.{' '}
					</p> */}
				</>
				{/* <Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								출산전후휴가 및 육아휴직, 육아기 근로시간 단축 등 휴직 내용
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>{processedData}</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table> */}
			</div>
		</div>
	);
}
