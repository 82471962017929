import {
	FETCH_COMPANIES,
	FETCH_AGENT_COMPANIES,
	DELETE_COMPANY,
	CLEAR_COMPANY,
	FETCH_WORKERS_OUT,
	DELETE_WORKER_OUT,
	CLEAR_WORKER_OUT,
	FETCH_WORKERS_IN,
	DELETE_WORKER_IN,
	CLEAR_WORKER_IN,
	FETCH_COMPETENCIES,
	DELETE_COMPETENCY,
	CLEAR_COMPETENCY,
	SEND_PAYDOCUID,
	SEND_PAYDOCUCOPY,
	SEND_PAYDOCUFIX,
	FETCH_COMPANY_PROGRAM_USE_CP,
	FETCH_PAYMENTSINFO,
	CLEAR_PAYMENTSINFO,
	FETCH_PAYDOCUS,
	DELETE_PAYDOCU,
	CLEAR_PAYDOCU,
	LISTEN_TO_SELECTED_PAYDOCU,
	CLEAR_SELECTED_PAYDOCU,
	SET_FILTER_PAYDOCU,
	SET_YEARMONTH_PAYDOCU,
	SET_FILTER_CLIENT_INFO,
	SET_WORKDATES_PAYDOCU,
	CLEAR_WORKDATES_PAYDOCU,
	SEND_PAYDOCU_COMPANYNAME,
	FINAL_RETIRE_HEALTHCARE_RECAL_FEE,
	FINAL_RETIRE_OLDCARE_RECAL_FEE,
	SET_PAYDOCU_WORKER_NAME,
	CLEAR_FINAL_RETIRE_HEALTHCARE_RECAL_FEE,
	CLEAR_FINAL_RETIRE_OLDCARE_RECAL_FEE,
	SUPER_ADMIN_FOR_FREE,
} from './companyConstants';

import {
	dataFromSnapshotIdDate,
	fetchAllPaydocusFromFirestore,
	fetchCompaniesFromFirestore,
	fetchAgentCompaniesFromFirestore,
	fetchCompanyProgramUseCPFromFirestore,
	fetchCompetencyFromFirestore,
	fetchInsureInAgentFromFirestore,
	fetchInsureInCompanyFromFirestore,
	fetchInsureInFromFirestore,
	fetchInsureOutAgentFromFirestore,
	fetchInsureOutCompanyFromFirestore,
	fetchInsureOutFromFirestore,
	fetchPaydocusFromFirestore,
	fetchPaydocusWorkerFromFirestore,
	fetchPaymentsInfoFromFirestore,
} from '../../app/firestore/firestoreService4In';

import {
	asyncActionError,
	asyncActionFinish,
	asyncActionStart,
} from '../../app/async/asyncReducer';

// 회사목록가져오기
export function fetchCompaniesFormFirestoreService4In() {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearCompany());
		try {
			const snapshot = await fetchCompaniesFromFirestore();
			const companies = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));
			dispatch({ type: FETCH_COMPANIES, payload: { companies } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

export function fetchAgentCompaniesFormFirestoreService4In(agentId) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearCompany());
		try {
			const snapshot = await fetchAgentCompaniesFromFirestore(agentId);
			console.log('Snapshot fetched:', snapshot);
			const companies = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));
			dispatch({ type: FETCH_AGENT_COMPANIES, payload: { companies } });
			dispatch(asyncActionFinish());
		} catch (error) {
			console.error(
				'Error in fetchAgentCompaniesFormFirestoreService4In:',
				error
			);
			dispatch(asyncActionError());
		}
	};
}

export function superAdminForFree(authLevel) {
	const superAdmin = authLevel >= 100 ? true : false;
	return {
		type: SUPER_ADMIN_FOR_FREE,
		payload: superAdmin,
	};
}

export function deleteCompany(companyId) {
	return {
		type: DELETE_COMPANY,
		payload: companyId,
	};
}

export function clearCompany() {
	return {
		type: CLEAR_COMPANY,
	};
}

// 퇴사자 목록 가져오기
export function fetchInsureOutFromFirestoreService4In() {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearWorkerOut());
		try {
			const snapshot = await fetchInsureOutFromFirestore();
			const workersOut = snapshot.docs.map((doc) =>
				dataFromSnapshotIdDate(doc)
			);
			dispatch({ type: FETCH_WORKERS_OUT, payload: { workersOut } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

// 퇴사자 목록 가져오기(개별회사)
export function fetchInsureOutCompanyFromFirestoreService4In(companyId) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearWorkerOut());
		try {
			const snapshot = await fetchInsureOutCompanyFromFirestore(companyId);
			const workersOut = snapshot.docs.map((doc) =>
				dataFromSnapshotIdDate(doc)
			);
			dispatch({ type: FETCH_WORKERS_OUT, payload: { workersOut } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

// 퇴사자 목록 가져오기(agent회사)
export function fetchInsureOutAgentFromFirestoreService4In(companyId) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearWorkerOut());
		try {
			const snapshot = await fetchInsureOutAgentFromFirestore(companyId);
			const workersOut = snapshot.docs.map((doc) =>
				dataFromSnapshotIdDate(doc)
			);
			dispatch({ type: FETCH_WORKERS_OUT, payload: { workersOut } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

// 퇴사자 목록 가져오기
export function fetchInsureInFromFirestoreService4In() {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearWorkerIn());
		try {
			const snapshot = await fetchInsureInFromFirestore();
			const workersIn = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));
			dispatch({ type: FETCH_WORKERS_IN, payload: { workersIn } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

// 개별회사 입사자 목록 가져오기
export function fetchInsureInCompanyFromFirestoreService4In(companyId) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearWorkerIn());
		try {
			const snapshot = await fetchInsureInCompanyFromFirestore(companyId);
			const workersIn = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));
			dispatch({ type: FETCH_WORKERS_IN, payload: { workersIn } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

// agent회사 입사자 목록 가져오기
export function fetchInsureInAgentFromFirestoreService4In(companyId) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearWorkerIn());
		try {
			const snapshot = await fetchInsureInAgentFromFirestore(companyId);
			const workersIn = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));
			dispatch({ type: FETCH_WORKERS_IN, payload: { workersIn } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

export function deleteWorkerOut(workerId) {
	return {
		type: DELETE_WORKER_OUT,
		payload: workerId,
	};
}

export function clearWorkerOut() {
	return {
		type: CLEAR_WORKER_OUT,
	};
}

export function deleteWorkerIn(workerId) {
	return {
		type: DELETE_WORKER_IN,
		payload: workerId,
	};
}

export function clearWorkerIn() {
	return {
		type: CLEAR_WORKER_IN,
	};
}

// 퇴사자 목록 가져오기
export function fetchCompetencyFromFirestoreService4In() {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearCompetency());
		try {
			const snapshot = await fetchCompetencyFromFirestore();
			const competencies = snapshot.docs.map((doc) =>
				dataFromSnapshotIdDate(doc)
			);
			dispatch({ type: FETCH_COMPETENCIES, payload: { competencies } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

export function deleteCompetency(workerId) {
	return {
		type: DELETE_COMPETENCY,
		payload: workerId,
	};
}

export function clearCompetency() {
	return {
		type: CLEAR_COMPETENCY,
	};
}

export function sendPaydocuId(id) {
	return {
		type: SEND_PAYDOCUID,
		payload: id,
	};
}

export function sendPaydocuCopy(copy) {
	return {
		type: SEND_PAYDOCUCOPY,
		payload: copy,
	};
}

export function sendPaydocuFix(fix) {
	return {
		type: SEND_PAYDOCUFIX,
		payload: fix,
	};
}

export function sendPaydocuCompnayName(compnayName) {
	return {
		type: SEND_PAYDOCU_COMPANYNAME,
		payload: compnayName,
	};
}

export function sendFinalRetireHealthCareRecalFee(fee) {
	return {
		type: FINAL_RETIRE_HEALTHCARE_RECAL_FEE,
		payload: fee,
	};
}

export function sendFinalRetireOldCareRecalFee(fee) {
	return {
		type: FINAL_RETIRE_OLDCARE_RECAL_FEE,
		payload: fee,
	};
}

export function clearFinalRetireHealthCareRecalFee() {
	return {
		type: CLEAR_FINAL_RETIRE_HEALTHCARE_RECAL_FEE,
	};
}

export function clearFinalRetireOldCareRecalFee() {
	return {
		type: CLEAR_FINAL_RETIRE_OLDCARE_RECAL_FEE,
	};
}

// 12월05일-공통
// 임금몀세서 목록가져오기
export function fetchAllPaydocusFormFirestoreService4In(
	filter,
	paydocuWorkerUid,
	yearMonth
) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearPaydocu());
		try {
			const snapshot = await fetchAllPaydocusFromFirestore(
				filter,
				paydocuWorkerUid,
				yearMonth
			);
			const paydocus = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));
			dispatch({ type: FETCH_PAYDOCUS, payload: { paydocus } });
			dispatch(asyncActionFinish());
		} catch (error) {
			console.error('Error fetching paydocus:', error); // 추가된 디버깅 로그
			dispatch(asyncActionError());
		}
	};
}

// 12월05일-공통
// 임금몀세서 목록가져오기
export function fetchPaydocusFormFirestoreService4In(
	filter,
	yearMonth,
	paydocuWorkerUid,
	clientInfo
) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		// dispatch(clearPaydocu());
		try {
			const snapshot = await fetchPaydocusFromFirestore(
				filter,
				yearMonth,
				paydocuWorkerUid,
				clientInfo
			);
			const paydocus = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));
			//fireStoreService.js : doc을 돌면서 doc에 firestoreTimeStamp가 있으면 자바 날짜 형식으로 바꾸기 함수
			dispatch({ type: FETCH_PAYDOCUS, payload: { paydocus } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

// 임금몀세서 목록가져오기
export function fetchPaydocusWorkerFormFirestoreService4In(
	workerName,
	paydocuWorkerCode,
	clientInfo
) {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearPaydocu());
		try {
			const snapshot = await fetchPaydocusWorkerFromFirestore(
				workerName,
				paydocuWorkerCode,
				clientInfo
			);
			// Check if the snapshot is empty
			if (snapshot.empty) {
				dispatch(asyncActionFinish());
				return; // exit the function if there are no documents
			}

			const paydocus = snapshot.docs.map((doc) => dataFromSnapshotIdDate(doc));

			//fireStoreService.js : doc을 돌면서 doc에 firestoreTimeStamp가 있으면 자바 날짜 형식으로 바꾸기 함수
			dispatch({ type: FETCH_PAYDOCUS, payload: { paydocus } });
			dispatch(asyncActionFinish());
		} catch (error) {
			console.log('Error fetching paydocus:', error);
			dispatch(asyncActionError());
		}
	};
}

export function setFilterPaydocu(value) {
	return function (dispatch) {
		dispatch({ type: SET_FILTER_PAYDOCU, payload: value });
	};
}

export function setFilterClientInfo(value) {
	return function (dispatch) {
		dispatch({ type: SET_FILTER_CLIENT_INFO, payload: value });
	};
}

export function setYearMonthPaydocu(value) {
	return function (dispatch) {
		dispatch({ type: SET_YEARMONTH_PAYDOCU, payload: value });
	};
}

export function setPaydocuWorkerName(value) {
	return function (dispatch) {
		dispatch({ type: SET_PAYDOCU_WORKER_NAME, payload: value });
	};
}

export function setWorkDatesPaydocu(value) {
	return function (dispatch) {
		dispatch({ type: SET_WORKDATES_PAYDOCU, payload: value });
	};
}

export function deletePaydocu(paydocuId) {
	return {
		type: DELETE_PAYDOCU,
		payload: paydocuId,
	};
}

export function clearPaydocu() {
	return {
		type: CLEAR_PAYDOCU,
	};
}

export function clearWorkDatesPaydocu() {
	return {
		type: CLEAR_WORKDATES_PAYDOCU,
	};
}

export function listenToSelectedPaydocu(selectedPaydocu) {
	return {
		type: LISTEN_TO_SELECTED_PAYDOCU,
		payload: selectedPaydocu,
	};
}

export function clearSelectedPaydocu() {
	return {
		type: CLEAR_SELECTED_PAYDOCU,
	};
}

// ------------

// 결재정보 가져오기
export function fetchPaymentsInfoFormFirestoreService4In() {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearPaymentsInfo());
		try {
			const snapshot = await fetchPaymentsInfoFromFirestore();
			const paymentsInfo = snapshot.docs.map((doc) =>
				dataFromSnapshotIdDate(doc)
			);
			//fireStoreService.js : doc을 돌면서 doc에 firestoreTimeStamp가 있으면 자바 날짜 형식으로 바꾸기 함수
			dispatch({ type: FETCH_PAYMENTSINFO, payload: { paymentsInfo } });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

// 전산이용계약프린트 정보
export function fetchCompanyProgramUseCPFormFirestoreService4In() {
	return async function (dispatch) {
		dispatch(asyncActionStart());
		dispatch(clearCompany());
		try {
			const snapshot = await fetchCompanyProgramUseCPFromFirestore().get();
			const companyProgramUseCPs = snapshot.docs.map((doc) =>
				dataFromSnapshotIdDate(doc)
			);
			//fireStoreService.js : doc을 돌면서 doc에 firestoreTimeStamp가 있으면 자바 날짜 형식으로 바꾸기 함수
			dispatch({
				type: FETCH_COMPANY_PROGRAM_USE_CP,
				payload: { companyProgramUseCPs },
			});
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
		}
	};
}

export function clearPaymentsInfo() {
	return {
		type: CLEAR_PAYMENTSINFO,
	};
}
