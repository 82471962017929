import React from 'react';
import { Table } from 'semantic-ui-react';

function ClientWageTableTop3TimeExplain({
	values,
	worker,
	handelInputChange,
	centerStyle,
	// payPaperAddMoneyYTOpen,
	centerStyleBlue,
	underInOut5daysWorker,
	centerStyleInOutBack,
	perDayMealTaxFree,
	centerStyleTeal,
	isRetireMonth,
}) {
	return (
		<>
			<Table.Row>
				{values.absentDays || values.absentDates ? (
					<>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							결근일자 :
						</Table.Cell>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							<input
								style={{ width: '100%' }}
								name='absentDates'
								placeholder='예) 8일'
								value={values.absentDates}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
							/>
						</Table.Cell>
					</>
				) : null}
				{values.absentPaidHoliDays || values.absentPaidHoliDates ? (
					<>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							주휴공제일자 :
						</Table.Cell>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							<input
								style={{ width: '100%' }}
								name='absentPaidHoliDates'
								placeholder='예) 17일'
								value={values.absentPaidHoliDates}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
							/>
						</Table.Cell>
					</>
				) : null}
				{values.absentTimes || values.absentTimeDates ? (
					<>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							공제시간설명 :
						</Table.Cell>
						<Table.Cell
							textAlign='center'
							colSpan='2'
							className='gubun'
							style={centerStyle}>
							<input
								style={{ width: '100%' }}
								name='absentTimeDates'
								placeholder='예) 18일 지각1시간'
								value={values.absentTimeDates}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
							/>
						</Table.Cell>
					</>
				) : null}
				{values.usedAnnualLeaves || values.usedAnnualLeaveDates ? (
					<>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							사용연차일자 :
						</Table.Cell>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							<input
								style={{ width: '100%' }}
								name='usedAnnualLeaveDates'
								placeholder='예) 5일~6일'
								value={values.usedAnnualLeaveDates}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
							/>
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9'>
					<span
						style={{
							fontSize: '1.2rem',
							color: 'darkcyan',
							fontWeight: 'bold',
						}}>{`3-1. ${worker}의 추가 지급금액 내역`}</span>
					{/* <span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '30px',
							cursor: 'pointer',
						}}>
						<span
							style={{ color: 'red' }}
							onClick={() => payPaperAddMoneyYTOpen()}>
							<Icon size='big' name='youtube' />
							상여금, 연차수당 등 설명
						</span>
					</span> */}
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9' style={{ color: 'blue' }}>
					* 상여금, 년간 미사용 연차수당은 퇴직금 등 평균임금 계산시 3/12로
					계산되고,{' '}
					<span style={{ color: 'teal' }}>
						{' '}
						근로제공과 관련없는 특별 상여금, 퇴직 미사용 연차수당(퇴직월에
						표시됨.)은 평균임금에서 제외됩니다.
					</span>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleBlue}>
					상여금
				</Table.Cell>
				{/* <Table.Cell textAlign='center' className='gubun' style={centerStyleBlue}>부정기상여금</Table.Cell> */}
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleBlue}>
					지급 <br /> 연차 <span style={{ color: 'red' }}>(일수)</span>
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					개인실적 <br /> 인센티브
				</Table.Cell>
				{underInOut5daysWorker ? (
					<Table.Cell
						textAlign='center'
						className='gubun'
						style={centerStyleInOutBack}>
						지급 조정
						<br />
						(일할 보정)
						<br />
						(과오납 등)
					</Table.Cell>
				) : (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						지급 조정
						<br />
						(과오납 등)
					</Table.Cell>
				)}
				<Table.Cell textAlign='center'>
					{perDayMealTaxFree ? (
						<div>
							식대 지급
							<br />
							출근일수
						</div>
					) : (
						''
					)}
				</Table.Cell>
				<Table.Cell textAlign='center'>
					전년 미사용 <br /> 연차 <span style={{ color: 'red' }}>(일수)</span>
				</Table.Cell>
				<Table.Cell textAlign='center'></Table.Cell>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleTeal}>
					기타금품 <br /> 특별상여금
				</Table.Cell>
				{isRetireMonth ? (
					<Table.Cell
						textAlign='center'
						className='gubun'
						style={centerStyleTeal}>
						퇴직 미사용<br></br>연차{' '}
						<span style={{ color: 'red' }}>(일수)</span>
					</Table.Cell>
				) : (
					<Table.Cell textAlign='center'></Table.Cell>
				)}
			</Table.Row>
		</>
	);
}

export default ClientWageTableTop3TimeExplain;
