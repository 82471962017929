import React from 'react';
import { josaRoType, josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLCWorkPlace({
	selectedClientReq4In,
	companyName,
	worker,
}) {
	const companyUn = josaUnType(companyName);
	const workFieldRo = josaRoType(selectedClientReq4In?.workDetailField);

	return (
		<div className='jau__contents'>
			<span style={{ fontSize: '1rem' }}>
				<strong>제2조 업무의 내용</strong>
			</span>
			<ul>
				<li>
					① {worker}의 담당업무는 {workFieldRo} 하고,
					{companyUn} 사업상 필요에 따라 {worker}의 담당업무를 변경할 수 있다.
				</li>
			</ul>
		</div>
	);
}
