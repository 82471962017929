import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../companyActions';
import PaydocuFilters from '../paydocuDashboard/PaydocuFilters';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import InsureManageListDayWorkerEntered from './InsureManageListDayWorkerEntered';
import InsureManageListDayWorkerRetired from './InsureManageListDayWorkerRetired';
import { isAgentF } from '../../../app/common/util/util';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocusDayWorkerTableDashboardReportHP() {
	const dispatch = useDispatch();
	const { paydocus, filter, yearMonth, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const isWorker = currentUserProfile?.isWorker;

	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel,
		currentUserProfile?.isAgent
	);

	const authLevel = currentUserProfile?.authLevel;

	const insureManageType = currentUserProfile?.insureManageType;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const paydocuWorkerUids = currentUserProfile?.paydocuWorkerUid;

	useEffect(() => {
		const fetchClientReqs = async () => {
			try {
				await dispatch(fetchClientReqs4In(companyName));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchClientReqs();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, companyName]);

	const paydocusDayWorker = paydocus.filter((worker) => worker?.dayWorker);

	useEffect(() => {
		const fetchPaydocus = async () => {
			try {
				await dispatch(
					fetchPaydocusFormFirestoreService4In(
						filter,
						yearMonth,
						paydocuWorkerUids,
						clientInfo
					)
				);
			} catch (error) {
				console.error('Error fetching paydocus:', error);
			}
		};

		fetchPaydocus();

		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, filter, yearMonth, paydocuWorkerUids, clientInfo]);

	const year = yearMonth.substr(0, 4);
	const month = yearMonth.substr(4, 2);

	function getLastDayOfMonth(yearMonth) {
		const year = parseInt(yearMonth.substring(0, 4));
		const month = parseInt(yearMonth.substring(4, 6));

		// 다음 달의 첫 번째 날을 계산한 후, 하루를 빼면 현재 월의 마지막 날을 얻을 수 있습니다.
		const date = new Date(year, month, 0);
		const day = date.getDate();

		return `${yearMonth}${day.toString().padStart(2, '0')}`;
	}

	function extractDayWorkerPaydocusInfo(dayWorkerPaydocus) {
		return dayWorkerPaydocus.map((item) => ({
			workerId: item?.workerInfo?.workerId,
			worker: item?.workerInfo?.worker,
			companyName: companyName,
			workerSocialNumberFront: item?.workerInfo?.workerSocialNumberFront,
			workerSocialNumberBack: item?.workerInfo?.workerSocialNumberBack,
			nationality: item?.workerInfo?.nationality,
			visaType: item?.workerInfo?.visaType,
			dayWorkerTaxWageSum: item?.dayWorkerInfo?.dayWorkerTaxWageSum,
			dayWorkerCalHealthCare: item?.dayWorkerInfo?.dayWorkerCalHealthCare,
			dayWorkerCalPension: item?.dayWorkerInfo?.dayWorkerCalPension,
			enteredDate: item?.info?.yearMonth + '01',
			retiredDate: getLastDayOfMonth(yearMonth),
			netAge: item?.netAge,
			netAgeEntered: item?.netAgeEntered,
			netAgePension: item?.netAgePension,
		}));
	}

	const dayWorkers = clientReqs4In.filter((worker) => worker?.isDayWorker);

	function extractDayWorkerInfo(dayWorkers) {
		return dayWorkers.map((item) => ({
			workerName: item?.worker?.name,
			workerId: item?.id,
			insureOutResponse: item?.insureOutResponse,
			insureInResponseDate: item?.insureInResponseDate,
			insureInResponse: item?.insureInResponse,
			insureOutResponseDate: item?.insureOutResponseDate,
		}));
	}

	const dayWorkerPaydocusInfo = extractDayWorkerPaydocusInfo(paydocusDayWorker);

	const dayWorkersInfo = extractDayWorkerInfo(dayWorkers);

	function mergeWorkerInfo(dayWorkerPaydocusInfo, dayWorkersInfo) {
		return dayWorkerPaydocusInfo.map((paydoc) => {
			const workerInfo = dayWorkersInfo.find(
				(worker) => worker.workerId === paydoc.workerId
			);
			if (workerInfo) {
				return {
					...paydoc,
					insureInResponse: workerInfo.insureInResponse,
					insureInResponseDate: workerInfo.insureInResponseDate,
					insureOutResponse: workerInfo.insureOutResponse,
					insureOutResponseDate: workerInfo.insureOutResponseDate,
				};
			}
			return paydoc;
		});
	}

	const mergedDayWorkerPaydocusInfo = mergeWorkerInfo(
		dayWorkerPaydocusInfo,
		dayWorkersInfo
	);

	useEffect(() => {
		if (!mergedDayWorkerPaydocusInfo) return;
		setSearchResults(mergedDayWorkerPaydocusInfo);
	}, [paydocus, clientReqs4In]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<h1 style={{ textAlign: 'center', margin: '50px' }}>
				{year}년 {month}월 일용/시급직 국민연금, 건강보험 당월 입퇴사 신고
			</h1>
			<PaydocuFilters loading={loading} isWorker={isWorker} isAgent={isAgent} />
			<>
				{isWorker ? null : (
					<>
						년월, 년, 월, 직원성명, 부서, 직위 검색 :{' '}
						<Input
							type='text'
							placeholder='Search'
							value={searchTerm || ''} // 수정된 부분
							onChange={handleChange}
						/>
						<br />
					</>
				)}
			</>
			<Grid>
				<Grid.Column width={16}>
					<>
						<hr />
						{authenticated && (
							<>
								<InsureManageListDayWorkerEntered
									searchResults={searchResults}
									companyName={companyName}
									searchTerm={searchTerm}
									insureManageType={insureManageType}
									authLevel={authLevel}
								/>
								<hr />
								<hr />
								<hr />
								<InsureManageListDayWorkerRetired
									searchResults={searchResults}
									companyName={companyName}
									searchTerm={searchTerm}
									insureManageType={insureManageType}
									authLevel={authLevel}
								/>
							</>
						)}
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
