import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import {
	addClientReq4InToFirestore,
	listenToClientReq4InFromFirestore,
	updateClientReq4InInFirestore,
} from '../../../../app/firestore/firestoreService4In';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import {
	clearSelectedClientReq4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import { Button, Segment } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CrwfDayWorkerInfo from './crwfDetail/CrwfDayWorkerInfo';
import { decrypto } from '../../../../app/firestore/firestoreService';
import SingleSelectRadioButtonDayWorker from './crwfDetail/SingleSelectRadioButtonDayWorker';
import {
	checkNoUserInfo,
	commaStringToNumber,
	freeComClient,
	lowestTimeWageOf2024,
	redirectToAppropriatePage,
} from '../../../../app/common/util/util';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';

export default function ClientReqDayWorkerForm({ match, history, location }) {
	const dispatch = useDispatch();
	const { clientReqs4In, selectedClientReq4In, workerRegistryType } =
		useSelector((state) => state.clientReq4InStore);
	const { filter, clientInfo } = useSelector((state) => state.companyReducer);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { loading, error } = useSelector((state) => state.async);
	const [week14OverBLTimeV, setWeek14OverBLTimeV] = useState('');

	const [openTax4In, setOpenTax4In] = useState(
		selectedClientReq4In?.pensionBaseWage ||
			selectedClientReq4In?.healthCareBaseWage ||
			selectedClientReq4In?.noJobBaseWage ||
			selectedClientReq4In?.taxPayType ||
			selectedClientReq4In?.oldCareBaseWage
			? true
			: false
	);
	//국민연금 월보험료 오픈
	const [openPensionPayType, setOpenPensionPayType] = useState(
		selectedClientReq4In?.pensionBaseWage ? true : false
	);

	//건강보험 월보험료 오픈
	const [openHealthCarePayType, setOpenHealthCarePayType] = useState(
		selectedClientReq4In?.healthCareBaseWage ? true : false
	);
	//고용보험 월보험료 오픈
	const [openNoJobPayType, setOpenNoJobPayType] = useState(
		selectedClientReq4In?.noJobBaseWage ? true : false
	);

	const [openOldCarePayType, setOpenOldCarePayType] = useState(
		selectedClientReq4In?.oldCareBaseWage ? true : false
	);
	useEffect(() => {
		if (location.pathname !== '/createClientReqDayWorker') return;
		dispatch(clearSelectedClientReq4In());
	}, [location.pathname, dispatch]);

	const data = selectedClientReq4In;
	const decryptoData = {};
	for (const prop in data) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront' || prop === 'workerSocialNumberBack'
				? decrypto(data[prop], String(process.env.CRYPTO_KEY))
				: data[prop];
	}

	const initialValues = decryptoData ?? {
		//CrwfDayWorkerBasicInfo
		companyName: '',
		company: { ceoName: '', addr: '' },
		worker: { name: '', comNumber: '', email: '' },
		workerEnteredDate: '',
		workerEndDate: '',
		workerSocialNumberFront: '',
		workerSocialNumberBack: '',
		foreigner: {
			nationality: '',
			visaType: '',
		},
		workerDivisions: '',
		noJobPayDetail: '',
		workDetailField: '',

		//CrwfDayWorkerTimeAndWageInfo
		daylyPay: {
			timeWage: '',
			paidOffdays: '',
			specialWorkConditionContents: '',
			wage: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
			constructOrNot: '',
			dayWorkerTax: '',
			payDay: '',
		},
		week14OverBLTime: '',

		//CrwfWorkerBasicInfoTax4In
		retiredDate: '',
		taxPayType: '',
		numOfTaxFamily: '',
		numOfTaxFamily7To20: '',
		pensionPayType: '',
		pensionBaseWage: '',
		healthCarePayType: '',
		healthCareBaseWage: '',
		oldCarePayType: '',
		oldCareBaseWage: '',
		noJobPayType: '',
		noJobBaseWage: '',
	};

	const workerRegistryTypeDb = selectedClientReq4In?.workerRegistryType
		? selectedClientReq4In?.workerRegistryType
		: '';
	const type1DayWorker = workerRegistryTypeDb
		? workerRegistryTypeDb === '4' ?? workerRegistryType === '4'
		: workerRegistryType === '4';
	const type2TimeWorker = workerRegistryTypeDb
		? workerRegistryTypeDb === '5' ?? workerRegistryType === '5'
		: workerRegistryType === '5';

	const isWorker = currentUserProfile?.isWorker;

	const isDayWorker =
		workerRegistryType === '4' || selectedClientReq4In?.isDayWorker
			? true
			: false;
	const isTimeWorker =
		workerRegistryType === '5' || selectedClientReq4In?.isTimeWorker
			? true
			: false;
	const isBizIncomeWorker =
		workerRegistryType === '6' || selectedClientReq4In?.isBizWorker
			? true
			: false;

	const validationSchema = Yup.object().shape({
		worker: Yup.object({
			name: Yup.string().required('필수항목'),
		}),
		workerEnteredDate: Yup.string().required('필수항목'),
		workerSocialNumberFront: Yup.string().required('필수항목'),
		workerSocialNumberBack: Yup.string().required('필수항목'),
		workerDivisions: Yup.string().required('필수항목'),
		workDetailField: Yup.string().required('필수항목'),
		daylyPay: Yup.object().when('isBizIncomeWorker', {
			is: false, // isBizIncomeWorker가 false일 때만 검증 규칙 적용
			then: Yup.object({
				timeWage: Yup.string()
					.when([], () =>
						type2TimeWorker ? Yup.string().required('필수항목') : Yup.string()
					)
					.nullable(),
				wage: Yup.string()
					.when([], () =>
						type1DayWorker ? Yup.string().required('필수항목') : Yup.string()
					)
					.nullable(),
				workingStartTime: Yup.string()
					.when([], () =>
						type1DayWorker ? Yup.string().required('필수항목') : Yup.string()
					)
					.nullable(),
				workingEndTimeNextDayOrNot: Yup.string()
					.when([], () =>
						type1DayWorker ? Yup.string().required('필수항목') : Yup.string()
					)
					.nullable(),
				workingEndTime: Yup.string()
					.when([], () =>
						type1DayWorker ? Yup.string().required('필수항목') : Yup.string()
					)
					.nullable(),
				dayBreakTimes: Yup.string()
					.when([], () =>
						type1DayWorker ? Yup.string().required('필수항목') : Yup.string()
					)
					.nullable(),
				breakTimeContents: Yup.string()
					.when([], () =>
						type1DayWorker ? Yup.string().required('필수항목') : Yup.string()
					)
					.nullable(),
				constructOrNot: Yup.string().required('필수항목'),
				dayWorkerTax: Yup.string().required('필수항목'),
			}),
			otherwise: Yup.object().nullable(), // isBizIncomeWorker가 true일 때는 검증 규칙을 적용하지 않음
		}),
		week14OverBLTime: Yup.string().when('isBizIncomeWorker', {
			is: false, // isBizIncomeWorker가 false일 때만 검증 규칙 적용
			then: Yup.string().required('필수항목'),
			otherwise: Yup.string().nullable(), // isBizIncomeWorker가 true일 때는 검증 규칙을 적용하지 않음
		}),
	});

	useFirestoreDoc({
		query: () =>
			match.params.id
				? listenToClientReq4InFromFirestore(match.params.id)
				: null,
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
		shouldExecute:
			match.params.id !== selectedClientReq4In?.id &&
			location.pathname !== '/createClientReq4In',
	});

	const lawBaselowWageIncludePaidHoliday = lowestTimeWageOf2024 * 1.2;

	const agentUid = clientInfo?.agentUid
		? clientInfo?.agentUid
		: currentUserProfile?.agentUid;

	const overFiveFreeComClient =
		freeComClient(currentUserProfile.authLevel, currentUserProfile?.ceoName) &&
		clientReqs4In.length >= 5;

	useEffect(() => {
		if (checkNoUserInfo(currentUserProfile)) {
			redirectToAppropriatePage(currentUserProfile, history);
		}
	}, [currentUserProfile, history]);

	if (loading)
		return (
			<LoadingComponent content='Loading Employee Joining service lists ...' />
		);
	if (error) return <Redirect to='/error' />;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				context={{ isBizIncomeWorker }}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						selectedClientReq4In &&
						selectedClientReq4In.worker.name === values.worker.name
							? await updateClientReq4InInFirestore(
									values,
									agentUid,
									clientInfo,
									selectedClientReq4In?.id
							  )
							: await addClientReq4InToFirestore(
									values,
									agentUid,
									clientInfo,
									isBizIncomeWorker
							  );
						setSubmitting(false);
						history.push('/clientReqs4In');
						dispatch(clearSelectedClientReq4In());
					} catch (error) {
						console.log(error.message);
						toast.error(error.message);
						setSubmitting(false);
					}
				}}
				enableReinitialize>
				{({ isSubmitting, dirty, isValid, values }) => (
					<Form className='ui form'>
						<Segment clearing>
							{selectedClientReq4In?.worker?.name ? null : (
								<SingleSelectRadioButtonDayWorker values={values} />
							)}
							<hr />
							<CrwfDayWorkerInfo
								currentUserProfile={currentUserProfile}
								values={values}
								workerRegistryType={
									workerRegistryTypeDb
										? workerRegistryTypeDb
										: workerRegistryType
								}
								selectedClientReq4In={selectedClientReq4In}
								dayWorker={isDayWorker}
								timeWorker={isTimeWorker}
								bizIncomeWorker={isBizIncomeWorker}
								openTax4In={openTax4In}
								setOpenTax4In={setOpenTax4In}
								openPensionPayType={openPensionPayType}
								setOpenPensionPayType={setOpenPensionPayType}
								openHealthCarePayType={openHealthCarePayType}
								setOpenHealthCarePayType={setOpenHealthCarePayType}
								openNoJobPayType={openNoJobPayType}
								setOpenNoJobPayType={setOpenNoJobPayType}
								openOldCarePayType={openOldCarePayType}
								setOpenOldCarePayType={setOpenOldCarePayType}
								lawBaselowWageIncludePaidHoliday={
									lawBaselowWageIncludePaidHoliday
								}
								week14OverBLTimeV={week14OverBLTimeV}
								setWeek14OverBLTimeV={setWeek14OverBLTimeV}
							/>
							{currentUserProfile?.authLevel > 100 ? (
								<Button
									loading={isSubmitting}
									disabled={
										isBizIncomeWorker
											? false
											: !dirty ||
											  isSubmitting ||
											  !isValid ||
											  overFiveFreeComClient
									}
									type='submit'
									floated='right'
									positive
									content='저장'
								/>
							) : (
								<Button
									loading={isSubmitting}
									disabled={
										isBizIncomeWorker
											? false
											: (values?.week14OverBLTime === '주휴수당 포함'
													? commaStringToNumber(values?.daylyPay?.timeWage) <
													  lawBaselowWageIncludePaidHoliday
													: commaStringToNumber(values?.daylyPay?.timeWage) <
													  lowestTimeWageOf2024) ||
											  !dirty ||
											  isSubmitting ||
											  !isValid ||
											  overFiveFreeComClient
									}
									type='submit'
									floated='right'
									positive
									content='저장'
								/>
							)}

							<Button
								disabled={isSubmitting}
								as={Link}
								to='/clientReqs4In'
								type='submit'
								floated='right'
								content='취소'
							/>
						</Segment>
					</Form>
				)}
			</Formik>
		</>
	);
}
